import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const BlogHero = ({header, subHeader, maxWidth='100%'}) => (
    <Container style={{maxWidth: maxWidth}}>
        <Row>
            <Col>
                <div style={{marginTop: '4rem'}}></div>
                <p>
                    <h1 style={{fontWeight: 900}}>
                        <Link to="/blog">
                        ⬅
                        </Link> {header}
                    </h1>
                </p>
                <p style={{fontWeight: 300}}>{subHeader}</p>
                <div style={{marginTop: '4rem'}}></div>
            </Col>
        </Row>
    </Container>
)

BlogHero.propTypes = {
}

export default BlogHero
