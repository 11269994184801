import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ReactGA from "react-ga";
import Twitter from "../images/icons/twitter.png";
import YouTube from "../images/icons/youtube.png";
import LinkedIn from "../images/icons/linkedin.png";
import Github from "../images/icons/github-logo.png";
import HeadShot from "../images/head_shot_3.jpg";
import Image1 from "../images/saas-side-hustle/1.jpg";
import Image2 from "../images/saas-side-hustle/2.png";
import PDF from "../files/saas-side-hustle.pdf";
import Button from "react-bootstrap/Button";
import BlogHero from "../components/blog-hero";

// Icon Pack: https://www.flaticon.com/packs/social-media-icons
// Icon Pack: https://www.flaticon.com/free-icon/github-logo_25231

class Support extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize("UA-115464417-1");
    if (!(window.location.hostname === "localhost")) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    // window.addEventListener('scroll', this.handleScroll);
  }

  handleClick = (event) => {

    ReactGA.event({
      category: 'Buttons',
      action: 'Click',
      label: event.target.id
    });

  }

  downloadButtonClicked = (e) => {
  }

  handleChange = (event) => {
  }

  handleScroll = (event) => {
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Support"
          keywords={[
          ]}
        />
        {/* <Navigation /> */}

        {/* <a href="#pricing">
					<Button style={{ borderRadius: 0 }} id="apply-now-top" variant="primary" size={'lg'} onClick={this.handleClick} block>Get</Button>
				</a> */}

        <div style={{ marginTop: 20 }} />

        <Container style={{ maxWidth: 650 }}>

          <Row>
            <Col style={{ textAlign: 'center' }}>
              <div style={{ marginTop: '4rem' }}></div>
              <p>
                <h1 style={{ fontWeight: 900, lineHeight: 1.4 }}>
                  Support
                </h1>
              </p>
              <div style={{ marginTop: '4rem' }}></div>
            </Col>
          </Row>

        </Container>

        <Container style={{ maxWidth: 650 }}>

          <Row>
            <Col>

              <p>Domestic Telephone Number: +91 78759 17569</p>
              <p>Company Address: Liya House, Pimpal Bhat, Papdy, Vasai - 401207</p>

              <p>If you have any further questions, shoot me an email on <b>mail@quinston.com</b> or hit me up on <a href="https://twitter.com/graphoarty" target="_blank" style={{ textDecoration: "underline" }}>Twitter @graphoarty</a></p>

              <p>Have a good one!</p>

            </Col>
          </Row>

        </Container >

        <div style={{ marginTop: 100 }}></div>

        {/* <a href="#pricing">
					<Button style={{ borderRadius: 0 }} id="apply-now-bottom" variant="primary" size={'lg'} onClick={this.handleClick} block></Button>
				</a> */}

      </Layout >
    );
  }
}

export default Support;
